@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@500&display=swap');

* {
  font-family: 'Lora', serif;
  box-sizing: border-box;
  color:white;
}

body {
  overflow-x: hidden;
}

.first-anim-entering {
  opacity : 1
}

.first-anim-entered {
  opacity: 1;
}

.first-anim-exiting {
  animation: first-anim-exiting 1s forwards;
}

.first-anim-exited {
  opacity: 0
}


@keyframes first-anim-exiting {
  0% {
    opacity : 1
  }
  100% {
    opacity : 0
  }
}

#logo {
  padding-left: 20px;
  font-family: ExodusStriped;
  color: white;
}

#nav {
  transition: 1s;
  border-bottom: rgba(255, 255, 255, 0.456) 1px solid;
}

.link {
  position: relative;
  font-weight: 600;
}

.link::after {
  border-radius: 5px;
  content: "";
  position: absolute;
  width: 0%;
  height: 3px;
  background-color: white;
  left: 50%;
  bottom: -10px;
  transition: all .2s ease-in-out;
}

.link:hover::after {
  width: 100%;
  left: 0
}

.weight-600 {
  font-weight: 600;
}


#orange-border {
  border: 2px #FF8D33 solid
}

#oyl-text {
  font-weight: 900;
  color: #FF8D33;
}

#questionnaire {
  display: grid;
  grid-template-columns: 2;
}

input, select {
  margin-top: auto;
  border: none;
  outline: none;
  height: 40px;
  width: 300px;
  font-size: 20px;
  background-color: #323445;
}

.text-small {
  font-size: calc(18px + 8 * (100vw / 5210));
}

.text-medium {
  font-size: calc(18px + 8 * (100vw / 1280));
}

.text-big {
  font-size: calc(24px + 24 * (100vw / 1280));
}

.text-huge {
  font-size: calc(24px + 36 * (100vw / 1280));
}


